import type * as React from "react";

import { motion } from "framer-motion";

const Logo = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={682.667}
		height={664}
		viewBox="0 0 512 498"
		fill="none"
		{...props}
	>
		<g fill="currentColor">
			{/* Central vertical shape */}
			<path d="M258.6 6.2c-7 12-10.7 24.1-13.1 42.6-.8 6.8-3.1 19.8-5 28.8-1.9 9.1-4.2 22-5 28.7-1.6 12.8-2.1 44.7-.7 44.7 1.6 0 5.1-4.5 7.1-9.1 1.4-3.1 2.4-8.6 3.1-18.4.7-7.7 2.3-20.3 3.6-28 1.3-7.7 3.1-18.7 4-24.5 2.6-16.7 2.4-16.5 5-5 5.6 24.3 7.7 34.5 10.5 52.4 3.1 20.1 5.2 26.3 10.2 29.9 1.5 1.2 3 1.8 3.3 1.4.8-.8-1.1-23.5-3.2-37.7-1.3-9.3-5.4-28.7-12.9-61-2.9-12.6-4.9-32.5-4.3-42 .3-3.9.4-7 .2-7s-1.4 1.9-2.8 4.2z" />

			{/* Left upper shape */}
			<path d="M133.1 29.1c-.7 1.9-1.7 8-2.3 13.6-2 19.5 2.6 35.6 14.8 51.3 11.8 15.3 15.6 23 22 44.4 2 6.6 4.9 15.2 6.4 19.1 2.9 7.4 9 18.5 10.1 18.5.3 0 1.4-1.5 2.4-3.4 1.8-3.3 1.8-3.6-.5-12.3-4.4-16.9-14.9-47.4-18.4-53.7-2-3.5-6.7-10.6-10.6-15.7-7.1-9.4-15.5-24.9-14.8-27.1.2-.6 2.6 2 5.3 5.8 2.7 3.8 12.8 14.8 22.5 24.4 19.6 19.3 23.6 24.4 31.6 39.3 5.6 10.5 9.9 15.8 14.6 18.3 3.6 1.9 3.8 1.8 3.8-1.1 0-6.8-11.7-30.3-21.1-42.5-3.2-4.2-12.9-14.4-21.6-22.6-18.8-17.8-28.8-30.8-37.2-47.8l-5.8-11.8-1.2 3.3z" />

			{/* Right upper shape */}
			<path d="M370.7 34.7c-6.7 9.8-17.5 21.3-30.5 32.8-27.4 24.1-38.5 42.1-46.1 74.4-1.1 4.7-1.9 8.7-1.7 8.9.9.8 6.6-3 9.3-6.3 1.7-2 4.2-7.3 5.8-11.8 6.6-19.1 14.4-31.7 28.4-45.7 11-11 24.9-24 25.6-24 1.1 0-2.5 5.8-9.9 16-16.9 23.2-24.2 43.9-25.3 71.7-.5 11.3-.3 13.6 1.1 15.8 4.1 6.2 5.8 2.2 10.5-25 4.5-25.3 7.9-34.1 21.2-54.2 9.1-13.9 11.3-18.2 14.4-29 2.8-9.5 4.4-25.9 2.8-28.7-.8-1.4-1.7-.5-5.6 5.1z" />

			{/* Right middle shape */}
			<path d="M459.5 114.2c-8.7 7.1-32.6 23-42.5 28.2-4.7 2.5-17.5 7.5-28.5 11.1-22.3 7.4-38.3 14.7-49.4 22.6-7.1 5-7.4 5.3-5 6.1 4.7 1.8 12.1.1 25.9-6 7.4-3.3 21.6-8.6 31.4-11.7 22.4-7.2 35.9-13.9 50-24.7 4.9-3.7 1.7.9-5.1 7.3-7.1 6.8-11.9 10.1-27.6 19.1-15.4 8.8-24.2 15.5-35 26.7-10.3 10.7-15.1 19.2-12.8 23 1.6 2.5 5 .1 19.9-14.3 15.2-14.5 20.7-18.6 39-29.1 19.4-11.2 28.7-20.2 36.7-35.6 3.9-7.6 10-25.2 9.3-27-.2-.3-3 1.6-6.3 4.3z" />

			{/* Left middle shape */}
			<path d="M25 119.3c0 .3 2.6 2 5.8 3.7 14.1 7.9 25.5 18.1 44.2 39.5 20.1 23 28 30.5 44 41.2 14.2 9.5 35.3 19.3 37.4 17.3 3-2.9-4.7-11.8-14.8-17.1-9.5-4.9-24.3-15-32.7-22.3C104.7 178 94 167 85 157c-8.9-9.9-17.6-19-19.3-20.2-1.7-1.2-2.5-2.4-1.9-2.6 2-.6 11.1 5.2 23.5 15.1 14.3 11.4 19.9 14.6 43.7 24.9 20.7 9 27.4 11.5 34.1 12.8 5.2 1 10.9-.1 12.9-2.5 1.4-1.7.8-2-33.5-16.7-26.5-11.3-36.2-16.3-45-23.3-26.4-20.8-34.9-24.4-59.7-25.2-8.2-.3-14.8-.3-14.8 0z" />

			{/* Central shape */}
			{/* Central shape */}
			<path d="M239.7 157.5c-16.6 3.2-34.8 12-48.7 23.7-13.1 11-25.3 30.6-30.2 48.3-3.2 11.8-3.2 34.7 0 45.9 6.8 23.5 21.9 45.2 40.3 57.7 7.9 5.4 22.7 12.3 30.4 14.3 33.4 8.6 69.6-.4 94.7-23.3 10.9-10 21.5-26.3 26.3-40.6 4.3-12.7 5.7-32.2 3.5-46.1-3.6-21.9-16.6-43.8-34.9-58.6-10.1-8.1-27.3-17-38.6-19.9-10.7-2.8-31.9-3.4-42.8-1.4z" />
			<motion.path
				d="M277.8 175.6c14.6 3.5 31.3 13.3 40.9 23.7 14.5 15.9 21.4 33.2 21.4 53.7.1 13.4-2.1 23-7.6 34.5-10.5 21.8-30.7 37.8-55 43.6-7.6 1.8-11.2 2-23 1.7-12.5-.4-15-.8-23.5-3.7-16.8-5.7-31.9-17-41.8-31.4-9.7-14-13.2-24.5-13.9-41.7-.6-16.1 1-24.1 7.6-37.6 11.7-23.5 35-40.1 62.6-44.4 8.1-1.2 24-.5 32.3 1.6z"
				animate={{
					fill: ["#FFF3C4", "#FCE588", "#FADB5F", "#FCE588", "#FFF3C4"],
				}}
				transition={{
					duration: 1,
					repeat: Number.POSITIVE_INFINITY,
					repeatType: "reverse",
					ease: "circInOut",
				}}
			/>

			{/* Right upper-middle shape */}
			<path d="M363 232.2c0 2 3.8 5.4 8.3 7.3 2.1.9 9.1 2.3 15.5 3.1 14.8 1.9 22.2 3.5 37.3 7.9 15.6 4.5 32.1 7.5 42.3 7.6l8.1.1-3.5 1.6c-4.1 1.8-19 2.1-47.5.8-14.7-.7-19.8-.5-27.7.8-16 2.6-24.8 6.9-28.3 13.7-.8 1.5-1.5 3.1-1.5 3.5 0 1 6.1-.1 17-3.1 14.7-3.9 25.2-4.9 43.5-3.9 39.9 2.2 50 .6 66.4-10.5 6.2-4.2 18.1-14.8 18.1-16 0-.4-3-.1-6.7.8-5.1 1.2-12.1 1.5-27.8 1.5-23-.1-25.3-.5-53.4-8.8-16.8-4.9-26.8-6.6-44.8-7.3-13.1-.5-15.3-.4-15.3.9z" />

			{/* Left upper-middle shape */}
			<path d="M138 235.1c-9.5 1.1-38.1 1.5-62 1-26.9-.6-40.8 2.6-55.8 12.7C13.9 253.1 1 264.6 1 265.9c0 .4 5.1-.1 11.3-1.1 8.8-1.5 14.6-1.8 27.2-1.5 14.5.4 17.2.8 29 4 7.2 2 15.6 4.5 18.9 5.6 8.4 3 24.6 5.9 36.7 6.7 10.2.6 27.5-.8 29.2-2.5 1.2-1.2-4.9-5.9-9.1-7-2-.5-9.1-1.2-15.6-1.6-15.9-.8-25.3-2.6-42.7-7.9-19.3-5.9-27.5-7.6-40-8.4l-10.4-.7 7.5-1.8c9.9-2.5 23.5-3.3 39.5-2.5 30.9 1.5 51.1.6 59.6-2.9 4.1-1.6 8.9-6.4 8.9-8.9 0-1.6-1.3-1.6-13-.3z" />

			{/* Right lower-middle shape */}
			<path d="M360 291.2c0 7.1 8.4 16.3 22.6 24.9 17.9 10.8 33.8 24.1 46.4 38.9 4 4.7 10 11.4 13.4 14.9l6.1 6.4-5-2.8c-5.4-3-10.5-6.9-20.3-15.6-6.1-5.5-21.3-14.2-30.3-17.5-24.2-8.8-45.7-15.4-50.3-15.4-6.3 0-8.5 1.2-7 3.7 1.9 3.2 19.2 11.1 37 16.8 22.6 7.3 34.4 13.2 45.9 22.8 5 4.2 11.3 9 14.2 10.7 11.7 7.2 26.5 11.3 38.6 10.8l6.9-.3-4.8-3.5c-8.5-6.1-18.3-16.1-29.9-30.5-10.8-13.5-23-26.1-32.5-33.7-5.5-4.4-48.9-32.8-50.1-32.8-.5 0-.9 1-.9 2.2z" />

			{/* Left lower-middle shape */}
			<path d="M138.5 299.3c-38 17.4-57.3 29.9-70.6 45.9-9.2 11-17.8 31.4-19.4 45.8l-.6 5.5 4.2-5c8.8-10.3 19.1-19.5 30.1-26.8 18-12.1 24.3-14.3 52.1-18.6 7.5-1.2 17.7-3.3 22.5-4.7 8.8-2.6 23.1-8.8 26.7-11.8l2-1.6-2-1.6c-2.9-2.3-11.5-1.3-26.5 3.1-6.9 2-19.7 4.7-28.5 6.1-23.8 3.7-32.1 6.5-46.7 15.9-4 2.7-8.4 5.5-9.8 6.46.7 15.9-4 2.7-8.4 5.5-9.8 6.4l-2.5 1.6 2.2-3c3.1-4.5 11.8-12.4 21.1-19.3 9.6-7.1 21.6-14 42.7-24.3 16.6-8.2 24.5-14.4 24.5-19.2 0-1.9-.5-2.7-1.7-2.7-1 0-9.9 3.8-19.8 8.3z M277.8 175.6 L277.8 175.6" />

			{/* Right lower shape */}
			<path d="M326.6 339.2c-3.2 4.5-1.7 13.3 7.1 41.8 4.3 14.2 8.2 22 18.6 37.4 9.6 14.4 18.4 32.6 15.8 32.6-.4 0-1.4-1.5-2.3-3.3-2.8-5.9-13.3-19-25.8-32.2-14.8-15.7-20.4-23.4-28-38.6-6.6-13.1-9.7-17.3-14.1-18.8-2.4-.8-3.1-.7-3.6.5-1.6 4.3 11.7 33.6 20.7 45.5 2.7 3.7 11.7 13.7 19.8 22.2 15.5 16.3 27.4 31.9 36.2 47.4 2.9 5.1 5.6 9 6 8.8 1.4-.9 3.4-14 3.3-22.2-.2-15.9-4.2-26.4-17.9-46.5-11.7-17.3-13.8-21.7-20.4-43.3-5.8-19-11-32.7-12.7-33.2-.6-.2-1.8.6-2.7 1.9z" />

			{/* Left lower shape */}
			<path d="M188.2 345.2c-1.2 1.8-6.2 12.3-11.2 23.3-11.1 24.5-15.7 32.6-27.4 48-12.8 16.9-17.8 27.6-19.8 43-1.1 8-.3 23.5 1.1 23.5.4 0 1.3-1.2 2-2.8 5.5-12 23.4-31.8 40.6-44.7 24.6-18.6 36-32.3 44.6-53.8 2.8-6.9 8-24.9 7.4-25.5-.8-.9-6.3 1.9-8.8 4.6-1.4 1.5-5.2 8.6-8.5 15.7-3.2 7.2-7.6 15.8-9.8 19-6 9.2-16.5 19.6-29.8 29.7-6.7 5.1-14.8 11.8-18 15-3.3 3.2-6.1 5.6-6.3 5.4-.8-.7 7.3-13.4 15-23.5 9.8-12.9 14.7-20.8 20.8-33.6 6.5-13.6 10.5-25.6 12-35.5 1-7 .9-8.4-.3-9.7-1.4-1.3-1.8-1.1-3.6 1.9z" />

			{/* Central lower shape */}
			<path d="M235 370.5c0 17.1 1.3 27.8 7 56.5 5.8 29.2 7.4 43.3 6.8 58.8-.5 11.4-.5 11.5 1.3 9.1 9.1-11.6 14.6-27.7 17.5-51.2.8-6.7 3.2-19.4 5.4-28.2 6.2-25.4 11.3-55.6 9.6-57.3-1.2-1.1-4 1.2-7.3 6.1-2.2 3.3-3.8 7.6-5.3 14.5-1.2 5.3-4.2 18-6.6 28.2-4.6 19.5-6.3 28.9-6.5 35.5l-.1 4-1-5c-.5-2.8-2.5-12.7-4.3-22.1-1.9-9.4-4.1-23.3-5-31-2.5-20.9-3.3-24.4-6.5-27.6-1.6-1.5-3.3-2.8-3.9-2.8-.7 0-1.1 4.2-1.1 12.5z" />
		</g>
	</svg>
);

export default Logo;
